exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-annies-pflegedienst-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/annies-pflegedienst/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-annies-pflegedienst-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-botanical-theme-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/botanical-theme/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-botanical-theme-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-cafe-siller-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/cafe-siller/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-cafe-siller-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-chess-program-in-java-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/chess-program-in-java/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-chess-program-in-java-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-loulouby-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/loulouby/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-loulouby-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-meister-maschinen-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/meister-maschinen/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-meister-maschinen-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-mt-rasenroboter-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/mt-rasenroboter/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-mt-rasenroboter-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-qew-berufsschule-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/qew-berufsschule/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-qew-berufsschule-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-raphs-bbq-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/raphs-bbq/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-raphs-bbq-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-torexpert-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/torexpert/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-torexpert-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-various-university-projects-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/various-university-projects/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-various-university-projects-index-mdx" */),
  "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-your-dress-index-mdx": () => import("./../../../src/templates/work-details.jsx?__contentFilePath=/opt/build/repo/content/work/your-dress/index.mdx" /* webpackChunkName: "component---src-templates-work-details-jsx-content-file-path-opt-build-repo-content-work-your-dress-index-mdx" */)
}

